import React, { FC } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { useField } from "../hooks/field";

export const BoolField: FC<{
  field: string;
  label: string;
  labelPlacement?: "end" | "start";
}> = ({ label, field, labelPlacement }) => {
  const { value, onChange } = useField<boolean>(field);
  return (
    <FormControlLabel
      sx={{ transform: "translateX(-11px)", ml: 0 }}
      control={
        <Switch checked={value} onChange={(_, checked) => onChange(checked)} />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};
