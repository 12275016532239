import React, { FC, ReactNode } from "react";
import { TextField as TextFieldBase } from "@mui/material";
import { useField } from "../hooks/field";
import { Condition } from "./condition";

export const TextField: FC<{
  field: string;
  label: ReactNode;
  type?: React.InputHTMLAttributes<unknown>["type"];
  labelPlacement?: "end" | "start";
  multiline?: boolean;
  readOnly?: boolean;
  required?: boolean;
  show?: (parent: any) => boolean;
}> = ({ field, label, type, multiline, readOnly, required, show }) => {
  const { value, valid, onChange } = useField(field);
  return (
    <Condition show={show}>
      <TextFieldBase
        type={type}
        size="small"
        label={label}
        value={value ?? (type === "number" ? 0 : "")}
        onChange={(event) =>
          onChange(
            type === "number"
              ? parseInt(event.target.value) || 0
              : event.target.value
          )
        }
        multiline={multiline}
        InputProps={{ readOnly }}
        required={required}
        onWheel={(e) => (e.target as any).blur()}
        error={!valid}
      />
    </Condition>
  );
};
