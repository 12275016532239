import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { FieldContext, useField } from "../hooks/field";

export const Sortable = <T extends object>({
  Component,
}: {
  Component: FC<
    HTMLAttributes<any> & {
      value: T;
      dragged: boolean;
      onChange?: (value: T) => void;
      onRemove?: () => void;
    }
  >;
}) => {
  const { value: values, validation, onChange } = useField<object[]>();
  const [dragging, setDragging] = useState<number | null>(null);
  const [hovered, setHovered] = useState<number | null>(null);
  useEffect(() => {
    if (dragging !== null && hovered !== null && dragging !== hovered) {
      [values[hovered], values[dragging]] = [values[dragging], values[hovered]];
      onChange && onChange(values);
      setDragging(hovered);
    }
  }, [dragging, hovered]);
  return (
    <Stack sx={{ width: "100%" }} spacing={1}>
      {values?.map((value, index) => (
        <Box
          key={index}
          onDragStart={(event) => {
            event.dataTransfer.effectAllowed = "move";
          }}
          onDrag={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (dragging === null) setDragging(index);
          }}
          onDragOver={() => {
            setHovered(index);
          }}
          onDragEnd={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setDragging(null);
            setHovered(null);
          }}
          draggable
        >
          <FieldContext.Provider
            value={{
              value,
              validation: validation[index],
              onChange: (itemValue) => {
                onChange &&
                  onChange(Object.assign([], values, { [index]: itemValue }));
              },
            }}
          >
            <Component
              value={value as T}
              dragged={dragging === index}
              onRemove={() => {
                onChange && onChange(values.filter((_, i) => i !== index));
              }}
            />
          </FieldContext.Provider>
        </Box>
      ))}
    </Stack>
  );
};
