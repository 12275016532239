import React, { PropsWithChildren, useCallback } from "react";
import { GroupField } from "./group-field";
import { Button, Stack } from "@mui/material";
import { Sortable } from "./sortable";
import { useField } from "../hooks/field";

export const GroupListField = <T,>({
  label,
  field,
  labelField = "name",
  included,
  children,
  defaultValue,
}: PropsWithChildren<{
  field: string;
  label: string;
  labelField?: string;
  included?: boolean;
  defaultValue: T;
}>) => {
  const renderItem = useCallback(
    ({ value, dragged, onRemove }) => (
      <GroupField
        label={
          (value[labelField] ?? value["title"] ?? value["label"]) as string
        }
        dragged={dragged}
        onRemove={onRemove}
        sortable
      >
        {children}
      </GroupField>
    ),
    [labelField]
  );
  const { value, onChange } = useField<T[]>(field);
  return (
    <GroupField field={field} label={label} included={included}>
      <Stack alignItems="flex-start" spacing={2}>
        <Button
          onClick={() => onChange([defaultValue, ...value])}
          size="small"
          variant="outlined"
        >
          Ajouter
        </Button>
        <Sortable Component={renderItem} />
      </Stack>
    </GroupField>
  );
};
