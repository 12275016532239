import React, { FC, ReactNode } from "react";
import { Box, Toolbar as ToolbarBase, Typography } from "@mui/material";

export const Toolbar: FC<{
  title: string;
  small?: boolean;
  icon?: ReactNode;
  actions?: ReactNode;
}> = ({ title, small, icon, actions }) => {
  return (
    <Box sx={{ height: "4rem" }}>
      <ToolbarBase
        sx={{
          bgcolor: "primary.main",
          position: "fixed",
          zIndex: 2,
          height: "4rem",
          left: small ? "auto" : "14rem",
          right: small ? "auto" : 0,
          width: small ? "14rem" : "auto",
          borderRight: small ? "1px solid rgba(0,0,0,0.12)" : "none",
          color: "primary.contrastText",
          display: "flex",
          alignItems: "center",
          px: 2,
        }}
        disableGutters
      >
        {icon}
        <Typography sx={{ ml: icon ? 1 : 0, flex: 1 }} variant="h6" noWrap>
          {title}
        </Typography>
        {actions}
      </ToolbarBase>
    </Box>
  );
};
