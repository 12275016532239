import React, { useCallback, useState } from "react";
import { GroupField } from "./group-field";
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useField } from "../hooks/field";
import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import { Sortable } from "./sortable";

export const ListField = ({
  label,
  field,
  included,
}: {
  field: string;
  label: string;
  included?: boolean;
}) => {
  const renderItem = useCallback(
    ({ value, dragged, onRemove }) => (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          px: 2,
          py: 0,
          borderColor: dragged ? "background.paper" : "grey.400",
          bgcolor: dragged ? "background.paper" : "white",
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: 1,
          minHeight: "34px",
          height: "34px",
        }}
        spacing={2}
      >
        <DragHandleOutlinedIcon
          fontSize="small"
          sx={{
            color: "text.secondary",
            cursor: "pointer",
            opacity: dragged ? 0 : 1,
          }}
        />
        <Typography
          sx={{
            flex: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            opacity: dragged ? 0 : 1,
          }}
        >
          {value}
        </Typography>
        <IconButton
          sx={{ opacity: dragged ? 0 : 1 }}
          size="small"
          onClick={onRemove}
        >
          <DeleteOutlineOutlined fontSize="small" />
        </IconButton>
      </Stack>
    ),
    []
  );
  const { value, onChange } = useField<string[]>(field);
  const [newValue, setNewValue] = useState("");
  return (
    <GroupField field={field} label={label} included={included}>
      <Stack sx={{ width: "100%" }} spacing={1}>
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            size="small"
            sx={{ ".MuiInputBase-input": { py: 0.5 } }}
            value={newValue}
            onChange={(event) => setNewValue(event.target.value)}
          />
          <Button
            onClick={() => {
              onChange([newValue, ...value]);
              setNewValue("");
            }}
            size="small"
            variant="outlined"
            disabled={newValue.length === 0}
          >
            Ajouter
          </Button>
        </Stack>
        <Sortable Component={renderItem} />
      </Stack>
    </GroupField>
  );
};
