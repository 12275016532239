import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { navigate, RouteComponentProps, Router, useMatch } from "@reach/router";
import {
  Discount,
  Fair,
  Product,
  Shop,
  Surcharge,
  validateShop,
  Zone,
  ZonePrice,
} from "../../api/shared/shop";
import LockIcon from "@mui/icons-material/Lock";
import { GroupField } from "../atoms/group-field";
import { ColorField } from "../atoms/color-field";
import { BoolField } from "../atoms/bool-field";
import { ChoiceField } from "../atoms/choice-field";
import { GroupListField } from "../atoms/group-list-field";
import { TextField } from "../atoms/text-field";
import { FieldContext } from "../hooks/field";
import { Toolbar } from "../molecules/toolbar";
import RefreshIcon from "@mui/icons-material/Refresh";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PublishIcon from "@mui/icons-material/Publish";
import SyncIcon from "@mui/icons-material/Sync";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Condition } from "../atoms/condition";
import { keyframes } from "@mui/system";
import { ListField } from "../atoms/list-field";
import { CartProvider } from "./cart";
import { Banner } from "../molecules/banner";
import { Footer } from "./footer";
import { Reassurances } from "../molecules/reassurances";
import { Products } from "./products";
import { ImageField } from "../atoms/image-field";
import { GatsbyShop, shop2GatsbyShop } from "../utils/shop";
import { useApi } from "../hooks/api";

export const ShopInfo: FC<RouteComponentProps> = () => (
  <Stack sx={{ p: 2 }} spacing={2}>
    <Card sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Boutique</Typography>
        <TextField field="name" label="Nom" required />
        <TextField field="baseline" label="Baseline" multiline />
        <ColorField field="color" label="Couleur" />
        <ImageField field="logo" label="Logo" />
        <ImageField field="banner" label="Bannière" />
      </Stack>
    </Card>
    <Card sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Réassurance</Typography>
        <GroupField field="reassurance" label="Réassurance" included>
          <Stack spacing={2}>
            <GroupField field="shipping" label="Livraison">
              <Stack spacing={2}>
                <TextField field="title" label="Titre" />
                <TextField field="description" label="Description" />
                <TextField field="information" label="Information" multiline />
              </Stack>
            </GroupField>
            <GroupField field="delay" label="Expédition">
              <Stack spacing={2}>
                <TextField field="title" label="Titre" />
                <TextField field="description" label="Description" />
                <TextField field="information" label="Information" multiline />
              </Stack>
            </GroupField>
            <GroupField field="payment" label="Paiement">
              <Stack spacing={2}>
                <TextField field="title" label="Titre" />
                <TextField field="description" label="Description" />
                <TextField field="information" label="Information" multiline />
              </Stack>
            </GroupField>
          </Stack>
        </GroupField>
      </Stack>
    </Card>
    <Card sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Mentions légales</Typography>
        <GroupField field="legal" label="Mentions légales" included>
          <TextField field="company" label="Société" />
          <TextField field="capital" label="Capital" />
          <TextField field="registeredOffice" label="Siège social" />
          <TextField field="phoneNumber" label="Numéro de téléphone" />
          <TextField field="siret" label="Siret" />
          <TextField field="intracommunityVAT" label="TVA Intracommunautaire" />
          <TextField field="responsible" label="Responsable de publication" />
          <TextField field="contact" label="Contact" />
        </GroupField>
      </Stack>
    </Card>
  </Stack>
);

export const ShopDiscounts: FC<RouteComponentProps> = () => (
  <Stack sx={{ p: 2 }} spacing={2}>
    <Card sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Remises</Typography>
        <GroupListField<Discount>
          field="discounts"
          label="Remises"
          included
          defaultValue={{
            name: "Nouvelle remise",
            start: new Date().toISOString().slice(0, 10),
            end: new Date(
              new Date().getFullYear() + 1,
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours(),
              new Date().getMinutes()
            )
              .toISOString()
              .slice(0, 10),
            trigger: "amount",
            amount: 0,
            discount: "products_amount_rebate",
            productsAmount: 0,
          }}
        >
          <TextField field="name" label="Nom" required />
          <TextField field="start" label="Début" required type="date" />
          <TextField field="end" label="Fin" required type="date" />
          <Divider />
          <ChoiceField
            field="trigger"
            label="Déclencheur"
            options={["amount", "quantity", "code", "unconditional"]}
          />
          <TextField
            field="amount"
            label="Montant"
            type="number"
            required
            show={({ trigger }) => trigger === "amount"}
          />
          <TextField
            field="quantity"
            label="Quantité"
            type="number"
            required
            show={({ trigger }) => trigger === "quantity"}
          />
          <TextField
            field="code"
            label="Code"
            required
            show={({ trigger }) => trigger === "code"}
          />
          <Divider />
          <ChoiceField
            field="discount"
            label="Réduction"
            options={[
              "products_amount_rebate",
              "products_percentage_rebate",
              "products_perbottle_rebate",
              "products_perbottle_price",
              "shipping_amount_rebate",
              "shipping_percentage_rebate",
              "shipping_perbottle_price",
            ]}
          />
          <TextField
            field="productsAmount"
            label="Remise sur les produits (€)"
            type="number"
            required
            show={({ discount }) => discount === "products_amount_rebate"}
          />
          <TextField
            field="productsPercentage"
            label="Remise sur les produits (%)"
            type="number"
            required
            show={({ discount }) => discount === "products_percentage_rebate"}
          />
          <TextField
            field="productsPerbottleAmount"
            label="Remise sur chaque produit (€)"
            type="number"
            required
            show={({ discount }) => discount === "products_perbottle_rebate"}
          />
          <TextField
            field="productsPerbottlePrice"
            label="Prix de chaque produit (€)"
            type="number"
            required
            show={({ discount }) => discount === "products_perbottle_price"}
          />
          <TextField
            field="shippingAmount"
            label="Remise sur la livraison (€)"
            type="number"
            required
            show={({ discount }) => discount === "shipping_amount_rebate"}
          />
          <TextField
            field="shippingPercentage"
            label="Remise sur la livraison (%)"
            type="number"
            required
            show={({ discount }) => discount === "shipping_percentage_rebate"}
          />
          <TextField
            field="shippingPerbottlePrice"
            label="Prix de la livraison par produit (€)"
            type="number"
            required
            show={({ discount }) => discount === "shipping_perbottle_price"}
          />
        </GroupListField>
      </Stack>
    </Card>
  </Stack>
);

export const ShopShipping: FC<RouteComponentProps> = () => (
  <GroupField field="shipping" label="Shipping" included>
    <Stack sx={{ p: 2 }} spacing={2}>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h5">Colisage</Typography>
          <TextField
            field="minimum"
            type="number"
            label="Minimum de bouteilles"
          />
          <TextField
            field="maximum"
            type="number"
            label="Maximum de bouteilles"
          />
          <TextField
            field="packing"
            type="number"
            label="Bouteilles par carton"
          />
        </Stack>
      </Card>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <GroupField field="clickAndCollect" label="Click & Collect" included>
            <Stack
              justifyContent="space-between"
              sx={{ mr: "-10px" }}
              direction="row"
            >
              <Typography variant="h5">Click & Collect</Typography>
              <BoolField
                field="enabled"
                label="Activée"
                labelPlacement="start"
              />
            </Stack>
            <Condition show={(parent) => parent.enabled}>
              <TextField field="address" label="Adresse" />
              <TextField field="zipcode" label="Code Postal" />
              <TextField field="city" label="Ville" />
              <GroupField field="opening" label="Ouverture">
                <Stack spacing={1}>
                  <TextField field="hours" label="Horaires" />
                  <Stack>
                    <BoolField field="monday" label="Ouvert le lundi" />
                    <BoolField field="tuesday" label="Ouvert le mardi" />
                    <BoolField field="wednesday" label="Ouvert le mercredi" />
                    <BoolField field="thursday" label="Ouvert le jeudi" />
                    <BoolField field="friday" label="Ouvert le vendredi" />
                    <BoolField field="saturday" label="Ouvert le samedi" />
                    <BoolField field="sunday" label="Ouvert le dimanche" />
                  </Stack>
                  <Divider />
                  <BoolField field="holidays" label="Ouvert les jours fériés" />
                </Stack>
              </GroupField>
            </Condition>
          </GroupField>
        </Stack>
      </Card>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <GroupField field="home" label="À Domicile" included>
            <Stack
              justifyContent="space-between"
              sx={{ mr: "-10px" }}
              direction="row"
            >
              <Typography variant="h5">À Domicile</Typography>
              <BoolField
                field="enabled"
                label="Activée"
                labelPlacement="start"
              />
            </Stack>
            <Condition show={(parent) => parent.enabled}>
              <TextField field="method" label="Méthode" />
              <GroupListField<Zone>
                field="zones"
                label="Zones"
                defaultValue={{
                  name: "Nouvelle zone",
                  exclusions: [],
                  prices: [],
                }}
              >
                <TextField field="name" label="Nom" />
                <ListField field="exclusions" label="Exclusions" />
                <GroupListField<ZonePrice>
                  field="prices"
                  label="Prix"
                  defaultValue={{
                    label: "Nouveau prix",
                    min: 0,
                    max: 0,
                    price: 0,
                  }}
                >
                  <TextField field="label" label="Libellé" />
                  <TextField field="min" label="Minimum" type="number" />
                  <TextField field="max" label="Maximum" type="number" />
                  <TextField field="price" label="Prix (cts)" type="number" />
                </GroupListField>
              </GroupListField>
              <GroupListField<Surcharge>
                field="surcharges"
                label="Majorations"
                defaultValue={{
                  name: "Nouvelle majoration",
                  inclusions: [],
                  price: 0,
                }}
              >
                <TextField field="name" label="Nom" />
                <ListField field="inclusions" label="Inclusions" />
                <TextField field="price" label="Prix (cts)" type="number" />
              </GroupListField>
            </Condition>
          </GroupField>
        </Stack>
      </Card>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h5">Sur événement</Typography>
          <Stack>
            <GroupListField<Fair>
              field="fairs"
              label="Salons"
              included
              defaultValue={{
                name: "Nouvel événement",
                start: new Date().toISOString().slice(0, 10),
                end: new Date().toISOString().slice(0, 10),
                closing: new Date().toISOString().slice(0, 10),
                address: "",
                zipcode: "",
                city: "",
              }}
            >
              <TextField field="name" label="Nom" />
              <TextField field="start" label="Début" type="date" />
              <TextField field="end" label="Fin" type="date" />
              <TextField field="closing" label="Cloture" type="date" />
              <TextField field="address" label="Adresse" />
              <TextField field="zipcode" label="Code postal" />
              <TextField field="city" label="Ville" />
            </GroupListField>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  </GroupField>
);

export const ShopProducts: FC<RouteComponentProps> = () => {
  return (
    <Stack sx={{ p: 2 }}>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h5">Produits</Typography>
          <GroupListField<Product>
            field="products"
            label="Produits"
            included
            defaultValue={{
              name: "Nouveau produit",
              sku: "",
              title: "",
              vintage: "",
              color: "rouge",
              location: "",
              capacity: 75,
              price: 0,
              datasheet: "",
              packing: 1,
              container: "bt",
              grape: [],
              alcohol: 0,
              show: false,
              inStock: true,
              limit: 0,
            }}
          >
            <TextField field="sku" label="SKU" required />
            <TextField field="name" label="Nom" required />
            <TextField field="vintage" label="Millésime" />
            <ImageField field="image" label="Image" />
            <TextField field="datasheet" label="Fiche technique" />
            <Divider />
            <Stack>
              <BoolField field="show" label="Visible" />
              <BoolField field="inStock" label="En stock" />
            </Stack>
            <Divider />
            <TextField field="title" label="Appellation" />
            <ListField field="grape" label="Cépages" />
            <Divider />
            <TextField field="alcohol" type="number" label="Alcool (%)" />
            <TextField field="capacity" type="number" label="Contenance (cl)" />
            <TextField field="location" label="Localisation" />
            <ChoiceField
              field="color"
              label="Couleur"
              options={["rouge", "blanc", "rose"]}
            />
            <Divider />
            <TextField field="price" type="number" label="Prix (cts)" />
            <TextField field="packing" type="number" label="Colisage" />
            <TextField
              field="limit"
              type="number"
              label="Limite par commande"
            />
          </GroupListField>
        </Stack>
      </Card>
    </Stack>
  );
};

export const ShopAdmin: FC<RouteComponentProps> = () => (
  <Stack sx={{ p: 2 }} spacing={2}>
    <Card sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Configuration</Typography>
        <BoolField field="enabled" label="Activée" />
        <TextField
          field="id"
          label={
            <Box>
              Identifiant{" "}
              <LockIcon sx={{ fontSize: 18, verticalAlign: "text-bottom" }} />
            </Box>
          }
          readOnly
        />
        <TextField field="url" label="Site web" />
        <TextField field="email" label="Email" />
        <TextField field="phone" label="Téléphone" />
      </Stack>
    </Card>
    <Card sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Hébergement</Typography>
        <BoolField field="customDomain" label="Domaine personnalisé" />
        <TextField field="domain" label="Domaine" />
      </Stack>
    </Card>
    <Card sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Stripe</Typography>
        <GroupField field="stripe" label="Stripe" included>
          <ChoiceField
            field="env"
            label="Environnement"
            options={["live", "test"]}
          />
          <TextField field="account" label="Compte" />
        </GroupField>
        <TextField field="fees" label="Commission (%)" />
      </Stack>
    </Card>
  </Stack>
);

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ShopView: FC<RouteComponentProps> = () => {
  const [_, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [preview, setPreview] = useState(false);
  const [order, setOrder] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [shop, setShop] = useState<Shop>();
  const [gatsbyShop, setGastbyShop] = useState<GatsbyShop>();
  const { shopId, "*": tab } = useMatch("/admin/shops/:shopId/*") as any;
  const { get, put } = useApi();
  const loadData = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await get(`/api/shops/${shopId}`);
      setShop(data);
    } catch {
      navigate("/admin/shops");
    } finally {
      setLoading(false);
    }
  };
  const reloadData = async () => {
    if (confirm("Recharger les données et annuler les modifications ?")) {
      setDirty(false);
      await loadData();
    }
  };
  const saveData = async () => {
    setDirty(false);
    setSaving(true);
    try {
      await put(`/api/shops/${shopId}`, shop);
    } catch {
      // TODO display error
    } finally {
      setSaving(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    setGastbyShop(shop2GatsbyShop(shop));
  }, [shop]);
  const TABS = [
    { label: "Boutique", path: "" },
    { label: `Produits (${shop?.products.length || 0})`, path: "products" },
    { label: `Remises (${shop?.discounts.length || 0})`, path: "discounts" },
    { label: "Livraison", path: "shipping" },
    { label: "Administration", path: "admin" },
  ];
  return (
    <Stack>
      <Toolbar
        title={shop?.name ?? ""}
        icon={<StorefrontIcon />}
        actions={
          <Stack alignItems="center" direction="row">
            <IconButton
              target="_blank"
              href={`https://${
                shop?.customDomain ? shop?.domain : `exoshop.app/${shop?.id}`
              }`}
              sx={{ color: "primary.contrastText" }}
              title="Voir la boutique"
              disabled={!shop}
            >
              <OpenInNewIcon />
            </IconButton>
            <IconButton
              sx={{ color: "primary.contrastText" }}
              title="Prévisualiser"
              onClick={() => setPreview(!preview)}
              disabled={!shop}
            >
              {preview ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
            <IconButton
              sx={{ mr: 1, color: "primary.contrastText" }}
              title="Recharger"
              onClick={reloadData}
              disabled={!shop}
            >
              {<RefreshIcon />}
            </IconButton>
            <Button
              onClick={saveData}
              size="small"
              variant="contained"
              disabled={!dirty}
              startIcon={
                saving ? (
                  <SyncIcon
                    sx={{ animation: `${spin} 1.5s linear infinite` }}
                  />
                ) : (
                  <PublishIcon />
                )
              }
              sx={{
                height: "2rem",
                bgcolor: "primary.contrastText",
                color: "primary.main",
                "&:hover": {
                  bgcolor: "primary.contrastText",
                },
                "&.Mui-disabled": {
                  bgcolor: "primary.contrastText",
                  color: "primary.main",
                  opacity: 0.5,
                },
              }}
              disableElevation
            >
              Publier
            </Button>
          </Stack>
        }
      />
      {preview && gatsbyShop && (
        <CartProvider shop={gatsbyShop}>
          <Stack>
            <Banner
              banner={gatsbyShop.banner}
              name={gatsbyShop.name}
              baseline={gatsbyShop.baseline}
            />
            <Reassurances shop={gatsbyShop} />
            <Products
              shop={gatsbyShop}
              root={gatsbyShop.customDomain ? "" : `/${gatsbyShop.id}`}
              preview
              onCheckout={() => setOrder(true)}
              onBack={() => setOrder(false)}
              order={order}
            />
            <Footer
              {...gatsbyShop}
              root={gatsbyShop.customDomain ? "" : `/${gatsbyShop.id}`}
            />
          </Stack>
        </CartProvider>
      )}
      {!preview && shop && (
        <FieldContext.Provider
          value={{
            value: shop,
            validation: validateShop(shop),
            onChange: (value) => {
              setShop(value);
              setDirty(true);
            },
          }}
        >
          <Box sx={{ height: "3rem" }}>
            <Tabs
              sx={{
                position: "fixed",
                zIndex: 2,
                bgcolor: "background.paper",
                left: "14rem",
                right: 0,
                borderBottom: "1px solid rgba(0,0,0,0.12)",
              }}
              value={tab}
            >
              {TABS.map(({ label, path }) => (
                <Tab
                  key={path}
                  label={label}
                  href={`/admin/shops/${shopId}/${path}`}
                  value={path}
                />
              ))}
            </Tabs>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ">*": { maxWidth: "50rem", width: "100%" },
            }}
          >
            <Router>
              <ShopInfo path="/" />
              <ShopProducts path="/products" />
              <ShopDiscounts path="/discounts" />
              <ShopShipping path="/shipping" />
              <ShopAdmin path="/admin" />
            </Router>
          </Box>
        </FieldContext.Provider>
      )}
    </Stack>
  );
};
