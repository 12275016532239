import { IGatsbyImageData } from "gatsby-plugin-image";
export const static2media = (
  shop: string,
  url: string,
  width: number,
  height: number,
  layout: "constrained" | "fixed" | "fullWidth"
): IGatsbyImageData | undefined => {
  return url?.length
    ? {
        layout,
        images: {
          fallback: {
            src: `/api/media/shops/${shop}/${url.slice(1)}`,
          },
        },
        width,
        height,
      }
    : undefined;
};
