import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Skeleton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { RouteComponentProps } from "@reach/router";
import { formatPrice } from "../../api/shared/utils";
import { Toolbar } from "../molecules/toolbar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import { useAdmin } from "../hooks/admin";
import { useApi } from "../hooks/api";

const MODES: Record<string, string> = {
  home: "Livraison à domicile",
  estate: "Livraison à la propriété",
  fair: "Livraison sur événement",
};

const OrderRow = ({
  id,
  oid,
  amount,
  created,
  shop,
  items,
  status,
  name,
  phone,
  email,
  mode,
  day,
  fair,
  address,
  zipcode,
  city,
  env,
}: Order & { env: "live" | "test" }) => {
  const { isAdmin, isSeller } = useAdmin();
  const [open, setOpen] = useState(false);
  const quantity = items.reduce((acc, { quantity }) => acc + quantity, 0);
  return (
    <>
      <TableRow
        sx={{
          td: {
            borderBottom: "none",
            color: status !== "succeeded" ? "#C52F30" : undefined,
            height: "2.875rem",
          },
        }}
      >
        <TableCell>
          {isAdmin ? (
            <Link
              href={`https://dashboard.stripe.com${
                env === "test" ? "/test" : ""
              }/payments/${id}`}
              target="_blank"
            >
              {oid}
            </Link>
          ) : (
            oid
          )}
        </TableCell>
        <TableCell>{isSeller ? MODES[mode] : shop}</TableCell>
        <TableCell>{status}</TableCell>
        <TableCell align="right">{isNaN(quantity) ? "-" : quantity}</TableCell>
        <TableCell align="right">{formatPrice(amount)}</TableCell>
        <TableCell align="right">
          {Intl.DateTimeFormat("fr", {
            dateStyle: "short",
            timeStyle: "short",
          }).format(created * 1000)}
        </TableCell>
        <TableCell align="right">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} sx={{ p: 0 }}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              p: 1,
              borderTop: "1px solid #e0e0e0",
              backgroundColor: "#fafafa",
            }}
          >
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ pb: 2, verticalAlign: "top", width: "50%" }}>
                    <Box sx={{ fontWeight: "bold", mb: 0.5 }}>Client</Box>
                    <Box sx={{ textTransform: "capitalize" }}>{name}</Box>
                    {phone && (
                      <Box>
                        Téléphone :{" "}
                        <Link href={`tel:${phone}`} target="_blank">
                          {phone}
                        </Link>
                      </Box>
                    )}
                    {email && (
                      <Box>
                        Mail :{" "}
                        <Link href={`mailto:${email}`} target="_blank">
                          {email}
                        </Link>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell sx={{ pb: 2, verticalAlign: "top", width: "50%" }}>
                    <Box sx={{ fontWeight: "bold", mb: 0.5 }}>
                      {MODES[mode] ?? "Aucune livraison"}
                    </Box>
                    {fair && (
                      <Box sx={{ textTransform: "capitalize" }}>{fair}</Box>
                    )}
                    <Box sx={{ textTransform: "capitalize" }}>{address}</Box>
                    <Box sx={{ textTransform: "capitalize" }}>
                      {zipcode} {city}
                    </Box>
                    {day && (
                      <Box sx={{ mt: 0.5 }}>
                        Retrait le{" "}
                        {dayjs(day, "YYYYMMDD").format("dddd D MMMM YYYY")}
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Produit</TableCell>
                  <TableCell align="right">Couleur</TableCell>
                  <TableCell align="right">Millésime</TableCell>
                  <TableCell align="right">Quantité</TableCell>
                  <TableCell align="right">Prix</TableCell>
                  <TableCell align="right">Montant</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(
                  ({ quantity, amount, name, color, vintage }, index) => (
                    <TableRow key={index}>
                      <TableCell>{name}</TableCell>
                      <TableCell align="right">{color ? color : "-"}</TableCell>
                      <TableCell align="right">
                        {vintage ? vintage : "-"}
                      </TableCell>
                      <TableCell align="right">{quantity}</TableCell>
                      <TableCell align="right">
                        {formatPrice(amount / quantity)}
                      </TableCell>
                      <TableCell align="right">{formatPrice(amount)}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SkeletonRow = () => {
  return (
    <TableRow sx={{ td: { height: "2.9375rem" } }}>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>
      <TableCell align="right" />
    </TableRow>
  );
};

type Order = {
  id: string;
  oid: string;
  status: string;
  shop: string;
  amount: number;
  created: number;
  name: string;
  phone: string;
  email: string;
  mode: string;
  fair: string;
  day: string;
  comment: string;
  address: string;
  zipcode: string;
  city: string;
  items: {
    name: string;
    color: string;
    vintage: string;
    quantity: number;
    price: number;
    amount: number;
  }[];
};

const EMPTY_PAGES = [undefined];

export const OrderList: FC<RouteComponentProps> = () => {
  const { isAdmin, isSeller, env: sellerEnv } = useAdmin();
  const [env, setEnv] = useState<"live" | "test">(sellerEnv ?? "live");
  const [q, setQ] = useState("");
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<(undefined | string)[]>(EMPTY_PAGES);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const { get } = useApi();
  const loadData = async () => {
    setLoading(true);
    const {
      data: { data, next },
    } = await get<{ next: string; data: Order[] }>(`/api/orders`, {
      params: { page: pages[page], q, env },
    });
    setOrders(data);
    setPages(Object.assign([], pages, { [page + 1]: next }));
    setLoading(false);
  };
  useEffect(() => {
    setPages(EMPTY_PAGES);
    setPage(0);
  }, [env]);
  useEffect(() => {
    const debounce = setTimeout(() => loadData(), 500);
    return () => clearTimeout(debounce);
  }, [page, env, q]);
  return (
    <Stack>
      <Toolbar icon={<ShoppingCartIcon />} title="Commandes" />
      <Paper
        sx={{
          height: "calc(100vh - 4rem)",
          display: "flex",
          flexDirection: "column",
        }}
        elevation={0}
      >
        <Stack
          direction="row"
          sx={{
            borderBottom: "1px solid rgba(0,0,0,0.12)",
            height: "3rem",
            px: 1,
          }}
          alignItems="center"
          spacing={1}
        >
          <TextField
            value={q}
            onChange={(event) => setQ(event.target.value)}
            size="small"
            placeholder="Recherche..."
            sx={{
              flex: 1,
              ".MuiInputBase-root": { pl: 1 },
              ".MuiInputBase-input": { py: 0.25 },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {isAdmin && (
            <>
              <Switch
                size="small"
                checked={env === "live"}
                onChange={(e) => setEnv(e.target.checked ? "live" : "test")}
                sx={{ margin: "0 -7px" }}
              />
              <Box>{env === "live" ? "Live" : "Test"}</Box>
            </>
          )}
        </Stack>
        <TableContainer
          sx={{
            ".MuiTableCell-head": {
              fontWeight: "bold",
            },
            flex: 1,
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>{isSeller ? "Livraison" : "Shop"}</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell align="right">Quantité</TableCell>
                <TableCell align="right">Montant</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                orders.map((order, index) => (
                  <OrderRow key={index} env={env} {...order} />
                ))}
              {loading &&
                new Array(10)
                  .fill(0)
                  .map((_, index) => <SkeletonRow key={index} />)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10]}
          count={-1}
          rowsPerPage={10}
          page={page}
          sx={{
            borderTop: "1px solid #e0e0e0",
          }}
          backIconButtonProps={{
            disabled: loading || page === 0,
          }}
          nextIconButtonProps={{
            disabled: loading,
          }}
          onPageChange={(_, page) => setPage(page)}
        />
      </Paper>
    </Stack>
  );
};
