import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useAdmin } from "../hooks/admin";
import { useField } from "../hooks/field";
import { useMatch } from "@reach/router";
import SyncIcon from "@mui/icons-material/Sync";
import { keyframes } from "@mui/system";
import { useApi } from "../hooks/api";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ToolBox: FC<{ onSelect: (path: string) => void }> = ({ onSelect }) => {
  const { get, put } = useApi();
  const { shopId } = useMatch("/admin/shops/:shopId/*") as any;
  const [saving, setSaving] = useState(false);
  const [media, setMedia] = useState<{ path: string }[]>([]);
  const loadMedia = async () => {
    try {
      const {
        data: { data },
      } = await get(`/api/media/shops/${shopId}`);
      setMedia(data);
    } catch {
      setMedia([]);
    }
  };
  useEffect(() => {
    loadMedia();
  }, []);
  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.length) {
      setSaving(true);
      const file = event.target.files[0];
      const path = file.name;
      const content = await new Promise<string | undefined>(
        (resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () =>
            resolve(reader.result?.toString().split(",").pop());
          reader.onerror = (error) => reject(error);
        }
      );
      try {
        if (content) {
          await put(`/api/media/shops/${shopId}/${path}`, content);
        }
      } catch {
        // TODO display error
      } finally {
        await loadMedia();
        setSaving(false);
      }
    }
  };
  return (
    <Grid sx={{ p: 2 }} spacing={2} container>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        item
        xs={12}
      >
        <Typography variant="h6">Media</Typography>
        <Button
          component="label"
          startIcon={
            saving ? (
              <SyncIcon sx={{ animation: `${spin} 1.5s linear infinite` }} />
            ) : (
              <DriveFolderUploadOutlinedIcon />
            )
          }
          size="small"
          variant="outlined"
          disabled={saving}
        >
          Upload
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            onChange={onChange}
          />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {media
        .slice()
        .sort((a, b) => a.path.localeCompare(b.path))
        .map(({ path }) => (
          <Grid key={path} item xs={6}>
            <Button
              sx={{
                p: 0,
                pt: "56.25%",
                border: 1,
                borderColor: "grey.300",
                borderRadius: 1,
              }}
              title={path}
              onClick={() => onSelect(path)}
              fullWidth
            >
              <Image path={path} />
            </Button>
          </Grid>
        ))}
    </Grid>
  );
};

const Image: FC<{ path?: string }> = ({ path }) => {
  const ref = useRef(null);
  const [src, setSrc] = useState<string>();
  const [visible, setVisible] = useState(false);
  const { shopId } = useMatch("/admin/shops/:shopId/*") as any;
  const handle = useCallback(
    (entries) => {
      if (!visible && entries[0].isIntersecting) {
        setVisible(true);
      }
    },
    [visible]
  );
  useEffect(() => {
    const observer = new IntersectionObserver(handle);
    if (ref.current) observer.observe(ref.current!);
    return () => {
      ref.current && observer.unobserve(ref.current!);
    };
  }, [ref, visible]);
  useEffect(() => {
    if (path && visible) setSrc(`/api/media/shops/${shopId}/${path.slice(2)}`);
    else setSrc(undefined);
  }, [path, visible]);
  return (
    <Box
      ref={ref}
      sx={{
        display: "block",
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        borderRadius: 1,
        overflow: "hidden",
        img: {
          display: "block",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      }}
    >
      {src && <img src={src} alt="" />}
    </Box>
  );
};

export const ImageField: FC<{
  field?: string;
  label?: string;
}> = ({ field, label }) => {
  const { value, onChange } = useField<string | undefined>(field);
  const { setModal } = useAdmin();
  const onEdit = () => {
    setModal(
      <ToolBox
        onSelect={(path) => {
          onChange(path);
          setModal(undefined);
        }}
      />
    );
  };
  return (
    <FormControl
      sx={{
        display: "block",
        position: "relative",
        border: 1,
        borderColor: "grey.300",
        borderRadius: 1,
        bgcolor: "background.paper",
        width: "100%",
        pt: "56.25%",
      }}
    >
      <InputLabel
        sx={{
          overflow: "visible",
          ":before": {
            content: "''",
            display: "block",
            bgcolor: "background.paper",
            position: "absolute",
            left: "-5px",
            right: "-5px",
            height: "100%",
            zIndex: "-1",
            opacity: 1,
            borderRadius: 1,
          },
        }}
        shrink
      >
        {label}
      </InputLabel>
      <Image path={value} />
      <Stack
        direction="row"
        spacing={1}
        sx={{
          display: "block",
          position: "absolute",
          top: 0,
          right: 0,
          p: 2,
        }}
      >
        <IconButton
          sx={{
            bgcolor: "white",
            ":hover": { bgcolor: "white" },
            boxShadow: 2,
          }}
          size="small"
          onClick={onEdit}
        >
          <EditOutlinedIcon />
        </IconButton>
        <IconButton
          sx={{
            bgcolor: "white",
            ":hover": { bgcolor: "white" },
            boxShadow: 2,
          }}
          size="small"
          onClick={() => onChange(undefined)}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Stack>
    </FormControl>
  );
};
