import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import { User } from "gotrue-js";
import { Toolbar } from "../molecules/toolbar";
import PeopleIcon from "@mui/icons-material/People";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { useAdmin } from "../hooks/admin";
import { Checked, Shop, validate } from "../../api/shared/shop";
import { FieldContext } from "../hooks/field";
import { TextField } from "../atoms/text-field";
import { ChoiceField } from "../atoms/choice-field";
import { useApi } from "../hooks/api";

type CreateUser = {
  email: string;
  password: string;
  full_name: string;
  role: string;
  shop: string;
};

const ToolBox: FC<{
  title: string;
  initialValue: CreateUser;
  shops: string[];
  onDone: (user: CreateUser) => void;
}> = ({ title, initialValue, shops, onDone }) => {
  const [value, onChange] = useState<CreateUser>(initialValue);
  const [validation, setValidation] = useState<Checked<CreateUser>>({
    full_name: false,
    role: false,
    email: false,
    password: false,
    shop: false,
  });
  useEffect(() => {
    if (value) {
      setValidation({
        full_name: !!value.full_name.length,
        role: !!value.role.length,
        email: !!value.email.length,
        password: value.password.length >= 8,
        shop: value.role !== "seller" || !!value.shop.length,
      });
    } else {
      setValidation({
        full_name: false,
        role: false,
        email: false,
        password: false,
        shop: false,
      });
    }
  }, [value]);
  return (
    <FieldContext.Provider
      value={{
        value,
        validation,
        onChange,
      }}
    >
      <Stack sx={{ p: 2 }} spacing={1}>
        <Typography variant="h6">{title}</Typography>
        <Divider />
        <TextField field="full_name" label="Nom" required />
        <ChoiceField
          field="role"
          label="Rôle"
          options={["seller", "stakeholder", "admin"]}
        />
        <ChoiceField
          field="shop"
          label="Boutique"
          options={shops}
          show={({ role }) => role === "seller"}
        />
        <TextField field="email" label="Email" required />
        <TextField
          type="password"
          field="password"
          label="Mot de passe"
          required
        />
        <Button
          variant="contained"
          onClick={() => onDone(value)}
          disableElevation
          disabled={!validate(validation)}
        >
          Valider
        </Button>
      </Stack>
    </FieldContext.Provider>
  );
};

export const Directory: FC<RouteComponentProps> = () => {
  const { setModal, user: loggedUser } = useAdmin();
  const [, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const { get, post, put, delete: remove } = useApi();
  const loadData = async () => {
    setLoading(true);
    try {
      const { data } = await get<User[]>("/api/users");
      setUsers(data);
    } catch {
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };
  const loadShops = async () => {
    const {
      data: { data },
    } = await get<{ data: Shop[] }>("/api/shops");
    return data.map(({ id }) => id).sort();
  };
  const onAdd = async () => {
    const shops = await loadShops();
    setModal(
      <ToolBox
        title="Nouvel utlisateur"
        shops={shops}
        initialValue={{
          full_name: "",
          email: "",
          password: "",
          role: "seller",
          shop: shops[0],
        }}
        onDone={async ({ full_name, email, password, role, shop }) => {
          try {
            await post<User[]>(`/api/users`, {
              email,
              password: password !== "EMPTY_PASSWORD" ? password : undefined,
              user_metadata: { full_name },
              app_metadata: {
                roles: [role],
                provider: "email",
                shop: role === "seller" ? shop : null,
              },
            });
          } catch {
            // TODO show error
          } finally {
            setModal(undefined);
            await loadData();
          }
        }}
      />
    );
  };
  const onEdit = async (user: User) => {
    const shops = await loadShops();
    setModal(
      <ToolBox
        title="Mise à jour"
        shops={shops}
        initialValue={{
          full_name: user.user_metadata?.full_name ?? "",
          email: user.email,
          password: "EMPTY_PASSWORD",
          role: user.app_metadata.roles?.[0] ?? "seller",
          shop: user.app_metadata.shop ?? shops[0],
        }}
        onDone={async ({ full_name, email, password, role, shop }) => {
          try {
            await put(`/api/users/${user.id}`, {
              email,
              password: password !== "EMPTY_PASSWORD" ? password : undefined,
              user_metadata: { full_name },
              app_metadata: {
                roles: [role],
                provider: "email",
                shop: role === "seller" ? shop : null,
              },
            });
          } catch {
            // TODO show error
          } finally {
            setModal(undefined);
            await loadData();
          }
        }}
      />
    );
  };
  const onRemove = async (user: User) => {
    try {
      await remove(`/api/users/${user.id}`);
    } catch {
      // TODO show error
    } finally {
      await loadData();
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <Stack>
      <Toolbar
        icon={<PeopleIcon />}
        title="Utilisateurs"
        actions={
          <Button
            onClick={onAdd}
            size="small"
            variant="contained"
            sx={{
              height: "2rem",
              bgcolor: "primary.contrastText",
              color: "primary.main",
              "&:hover": {
                bgcolor: "primary.contrastText",
              },
              "&.Mui-disabled": {
                bgcolor: "primary.contrastText",
                color: "primary.main",
                opacity: 0.5,
              },
            }}
            disableElevation
          >
            Ajouter
          </Button>
        }
      />
      <Paper
        sx={{
          height: "calc(100vh - 4rem)",
          display: "flex",
          flexDirection: "column",
        }}
        elevation={0}
      >
        <TableContainer
          sx={{
            ".MuiTableCell-head": {
              fontWeight: "bold",
            },
            flex: 1,
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Shop</TableCell>
                <TableCell>Last login</TableCell>
                <TableCell sx={{ w: 0 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice()
                .sort((a, b) =>
                  a.user_metadata.full_name.localeCompare(
                    b.user_metadata.full_name
                  )
                )
                .map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{user.user_metadata.full_name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {(user.app_metadata.roles || []).join(", ")}
                    </TableCell>
                    <TableCell>{user.app_metadata.shop || "-"}</TableCell>
                    <TableCell>
                      {user.app_metadata.logged_at
                        ? new Intl.DateTimeFormat("fr-FR", {
                            dateStyle: "short",
                            timeStyle: "short",
                          }).format(new Date(user.app_metadata.logged_at))
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row">
                        <IconButton
                          size="small"
                          onClick={() => {
                            onEdit(user);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (
                              confirm(
                                "Souhaitez-vous supprimer l'utilisateur ?"
                              )
                            )
                              onRemove(user);
                          }}
                          disabled={user.email === loggedUser?.email}
                        >
                          <DeleteOutlineOutlined fontSize="small" />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
};
