import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { useField } from "../hooks/field";

export const Condition: FC<
  PropsWithChildren<{
    show?: (parent: any) => boolean;
  }>
> = ({ children, show }) => {
  const { parent } = useField();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(!show || (parent && show(parent)));
  }, [parent]);
  return <>{visible ? children : null}</>;
};
