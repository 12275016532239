import { createContext, useContext } from "react";
import { Checked, validate } from "../../api/shared/shop";

export const FieldContext = createContext<{
  value?: any;
  validation?: any;
  onChange: (value: any) => void;
}>({
  onChange: () => void 0,
});

export const useField = <T,>(
  field?: string
): {
  parent: any;
  value: T;
  valid: boolean;
  validation: Checked<T>;
  onChange: (newValue: T) => void;
} => {
  const { value, validation, onChange } = useContext(FieldContext);
  return {
    parent: value,
    value: field ? value[field] : value,
    validation: field ? validation[field] : validation,
    valid: validate(field ? validation[field] : validation),
    onChange: (newValue: any) =>
      onChange(field ? { ...value, [field]: newValue } : newValue),
  };
};
