import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import GoTrue from "gotrue-js";
import { useAdmin } from "../hooks/admin";
import { LogoIcon } from "../atoms/logo-icon";

export const LoginView = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUser } = useAdmin();
  const login = async () => {
    const auth = new GoTrue({
      APIUrl: "https://exoshop.app/.netlify/identity",
      setCookie: false,
    });
    try {
      setUser(await auth.login(email, password, true));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card sx={{ p: 2, minWidth: "300px" }} elevation={10}>
        <Stack spacing={1}>
          <Typography
            variant="h2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LogoIcon sx={{ color: "primary.main", mr: 1 }} />
            Exoshop
          </Typography>
          <Divider />
          <TextField
            name="email"
            size="small"
            label="Email"
            fullWidth
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            name="password"
            size="small"
            label="Mot de passe"
            type="password"
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Divider />
          <Button
            onClick={() => login()}
            variant="contained"
            disableElevation
            fullWidth
          >
            Connexion
          </Button>
        </Stack>
      </Card>
    </Box>
  );
};
