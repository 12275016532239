import { MenuItem, TextField } from "@mui/material";
import React, { FC } from "react";
import { useField } from "../hooks/field";
import { Condition } from "./condition";

export const ChoiceField: FC<{
  field: string;
  label: string;
  options: string[];
  show?: (parent: any) => boolean;
}> = ({ label, field, options = [], show }) => {
  const { value, valid, onChange } = useField(field);
  return (
    <Condition show={show}>
      <TextField
        select
        fullWidth
        label={label}
        size="small"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        error={!valid}
      >
        {options?.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Condition>
  );
};
