import { IGatsbyImageData } from "gatsby-plugin-image";
import { GenericProduct, GenericShop, Shop } from "../../api/shared/shop";
import { static2media } from "./image";

export type GatsbyShop = GenericShop<
  {
    childImageSharp?: {
      gatsbyImageData: IGatsbyImageData;
    };
  },
  GatsbyProduct
>;

export type GatsbyProduct = GenericProduct<{
  childImageSharp?: {
    small: IGatsbyImageData;
    big: IGatsbyImageData;
    shopping: IGatsbyImageData;
  };
}>;

export const shop2GatsbyShop = (
  shop: Shop | undefined
): GatsbyShop | undefined =>
  shop
    ? {
        ...shop,
        logo: shop.logo
          ? {
              childImageSharp: {
                gatsbyImageData: static2media(
                  shop.id,
                  shop.logo,
                  44,
                  44,
                  "fixed"
                )!,
              },
            }
          : undefined,
        banner: shop.banner
          ? {
              childImageSharp: {
                gatsbyImageData: static2media(
                  shop.id,
                  shop.banner,
                  1,
                  2 / 3,
                  "fullWidth"
                )!,
              },
            }
          : undefined,
        products: shop?.products?.map((product) => ({
          ...product,
          image: product.image
            ? {
                childImageSharp: {
                  big: static2media(
                    shop.id,
                    product.image,
                    640,
                    442,
                    "constrained"
                  )!,
                  small: static2media(
                    shop.id,
                    product.image,
                    40,
                    40,
                    "constrained"
                  )!,
                  shopping: static2media(
                    shop.id,
                    product.image,
                    300,
                    300,
                    "constrained"
                  )!,
                },
              }
            : undefined,
        })),
      }
    : undefined;
