export const avatar = (name: string) => {
  let avatar = "";
  if (name) {
    const m = new RegExp(/\b([a-zA-Z])/g);
    for (let match = m.exec(name); match; match = m.exec(name)) {
      avatar += match[1];
    }
  }
  return avatar.toUpperCase();
};
