import {
  Box,
  Card,
  CardActions,
  FormControl,
  FormGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import { Button } from "../atoms/button";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { RouteComponentProps } from "@reach/router";
import { Toolbar } from "../molecules/toolbar";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useApi } from "../hooks/api";

const minYear = new Date().getFullYear() - 100 + 1;

export const ShopForm: FC<RouteComponentProps> = () => {
  const { post } = useApi();
  const [account, setAccount] = useState();
  const [processing, setProcessing] = useState(false);
  const [form, setForm] = useState({
    test: true,
    business_name: "",
    business_tax_id: "",
    dob_day: "",
    dob_month: "",
    dob_year: "",
    address: "",
    zip: "",
    city: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    personal_address: "",
    personal_zip: "",
    personal_city: "",
    iban: "",
    file: "",
  });
  const proceed = () => {
    setProcessing(true);
    (async () => {
      try {
        const key = form.test
          ? process.env.STRIPE_PUBLIC_KEY_TEST
          : process.env.STRIPE_PUBLIC_KEY_LIVE;
        const stripe = await loadStripe(String(key));
        if (stripe) {
          const data = new FormData();
          data.append("file", form.file);
          data.append("purpose", "identity_document");
          const {
            data: { id },
          } = await axios.post("https://uploads.stripe.com/v1/files", data, {
            headers: { Authorization: `Bearer ${key}` },
          });
          const { token: accountToken, error: accountError } =
            await stripe.createToken("account", {
              business_type: "company",
              company: {
                name: form.business_name,
                tax_id: form.business_tax_id,
                phone: form.phone,
                address: {
                  line1: form.address,
                  city: form.city,
                  postal_code: form.zip,
                },
                directors_provided: true,
                executives_provided: true,
                owners_provided: true,
              },
              tos_shown_and_accepted: true,
            });
          if (accountError) {
            alert(accountError.message);
            return;
          }
          const { token: personToken, error: personError } =
            await stripe.createToken("person", {
              relationship: {
                title: "Propriétaire",
                owner: true,
                representative: true,
              },
              first_name: form.first_name,
              last_name: form.last_name,
              email: form.email,
              phone: form.phone,
              address: {
                line1: form.personal_address,
                city: form.personal_city,
                postal_code: form.personal_zip,
              },
              dob: {
                day: +form.dob_day,
                month: +form.dob_month,
                year: +form.dob_year,
              },
              verification: {
                document: {
                  front: id,
                },
                additional_document: {
                  front: id,
                },
              },
            });
          if (personError) {
            alert(personError.message);
            return;
          }
          const { token: bankToken, error: bankError } =
            await stripe.createToken("bank_account", {
              account_number: form.iban,
              country: "FR",
              currency: "eur",
              account_holder_type: "company",
            });
          if (bankError) {
            alert(bankError.message);
            return;
          }
          const {
            data: { account },
          } = await post("/api/accounts", {
            test: form.test,
            accountToken: accountToken?.id,
            personToken: personToken?.id,
            bankToken: bankToken?.id,
          });
          setAccount(account);
        }
      } catch (e) {
        alert(e.message);
      } finally {
        setProcessing(false);
      }
    })();
  };
  return (
    <>
      <Toolbar icon={<AttachMoneyIcon />} title="Stripe" />
      {account ? (
        <Card elevation={0} sx={{ p: 1 }}>
          <Typography>
            Le compte{" "}
            <a
              target="_blank"
              href={`https://dashboard.stripe.com/test/applications/users/${account}`}
            >
              {account}
            </a>{" "}
            a été créé avec succès.
          </Typography>
        </Card>
      ) : (
        <Card elevation={0}>
          <Box sx={{ p: 1 }}>
            <Typography variant="h6">Création de compte Stripe</Typography>
            <FormControl
              sx={{ my: 1 }}
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                fullWidth
                native
                value={form.test ? "test" : "live"}
                onChange={(e) =>
                  setForm({ ...form, test: e.target.value === "test" })
                }
                disabled={processing}
              >
                <option value="test">Compte de test</option>
                <option value="live">Compte de production</option>
              </Select>
            </FormControl>
          </Box>
          <FormGroup sx={{ p: 1 }}>
            <Typography>Entité légale</Typography>
            <TextField
              label="Raison Sociale"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              value={form.business_name}
              onChange={(e) =>
                setForm({ ...form, business_name: e.target.value })
              }
              disabled={processing}
              required
            />
            <TextField
              label="SIREN"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              value={form.business_tax_id}
              onChange={(e) =>
                setForm({ ...form, business_tax_id: e.target.value })
              }
              disabled={processing}
              required
            />
            <TextField
              label="Adresse"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              value={form.address}
              onChange={(e) => setForm({ ...form, address: e.target.value })}
              disabled={processing}
              required
            />
            <Box sx={{ mb: 1, display: "flex" }}>
              <TextField
                label="Code Postal"
                size="small"
                value={form.zip}
                onChange={(e) => setForm({ ...form, zip: e.target.value })}
                disabled={processing}
                required
              />
              <TextField
                label="Ville"
                size="small"
                fullWidth
                sx={{ ml: 1 }}
                value={form.city}
                onChange={(e) => setForm({ ...form, city: e.target.value })}
                disabled={processing}
                required
              />
            </Box>
          </FormGroup>
          <FormGroup sx={{ p: 1 }}>
            <Typography>Représentant</Typography>
            <Box sx={{ mb: 1, display: "flex" }}>
              <TextField
                label="Prénom"
                size="small"
                value={form.first_name}
                onChange={(e) =>
                  setForm({ ...form, first_name: e.target.value })
                }
                disabled={processing}
                required
              />
              <TextField
                label="Nom"
                size="small"
                fullWidth
                sx={{ ml: 1 }}
                value={form.last_name}
                onChange={(e) =>
                  setForm({ ...form, last_name: e.target.value })
                }
                disabled={processing}
                required
              />
            </Box>
            <Box sx={{ display: "flex", mb: 1 }}>
              <FormControl
                sx={{
                  flexGrow: 0,
                  flexShrink: 0,
                  flexBasis: "25%",
                }}
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  sx={{
                    color:
                      form.dob_day === "" ? "rgba(0, 0, 0, 0.25)" : "inherit",
                  }}
                  native
                  value={form.dob_day}
                  onChange={(e) =>
                    setForm({ ...form, dob_day: e.target.value })
                  }
                  disabled={processing}
                >
                  <option value="">Jour</option>
                  {new Array(31).fill(0).map((_, v) => (
                    <option key={v} value={v + 1}>
                      {v + 1}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  ml: 1,
                  flexGrow: 0,
                  flexShrink: 0,
                  flexBasis: "25%",
                }}
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  sx={{
                    color:
                      form.dob_month === "" ? "rgba(0, 0, 0, 0.25)" : "inherit",
                  }}
                  native
                  value={form.dob_month}
                  onChange={(e) =>
                    setForm({ ...form, dob_month: e.target.value })
                  }
                  disabled={processing}
                >
                  <option value="">Mois</option>
                  {new Array(12).fill(0).map((_, v) => (
                    <option key={v} value={v + 1}>
                      {Intl.DateTimeFormat("fr", { month: "long" }).format(
                        Date.UTC(1970, v)
                      )}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{ ml: 1 }}
                variant="outlined"
                size="small"
                fullWidth
              >
                <Select
                  sx={{
                    color:
                      form.dob_year === "" ? "rgba(0, 0, 0, 0.25)" : "inherit",
                  }}
                  native
                  value={form.dob_year}
                  onChange={(e) =>
                    setForm({ ...form, dob_year: e.target.value })
                  }
                  disabled={processing}
                >
                  <option value="">Année</option>
                  {new Array(100).fill(0).map((_, v) => (
                    <option key={v} value={v + minYear}>
                      {v + minYear}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextField
              label="Email"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              disabled={processing}
              required
            />
            <TextField
              label="Téléphone"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              value={form.phone}
              onChange={(e) => setForm({ ...form, phone: e.target.value })}
              disabled={processing}
              required
            />
            <TextField
              label="Adresse"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              value={form.personal_address}
              onChange={(e) =>
                setForm({ ...form, personal_address: e.target.value })
              }
              disabled={processing}
              required
            />
            <Box sx={{ mb: 1, display: "flex" }}>
              <TextField
                label="Code Postal"
                size="small"
                value={form.personal_zip}
                onChange={(e) =>
                  setForm({ ...form, personal_zip: e.target.value })
                }
                disabled={processing}
                required
              />
              <TextField
                label="Ville"
                size="small"
                fullWidth
                sx={{ ml: 1 }}
                value={form.personal_city}
                onChange={(e) =>
                  setForm({ ...form, personal_city: e.target.value })
                }
                disabled={processing}
                required
              />
            </Box>
            <TextField
              label="Carte d'identité"
              type="file"
              size="small"
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setForm({ ...form, file: (e.target as any).files[0] })
              }
              disabled={processing}
              required
            />
          </FormGroup>
          <FormGroup sx={{ p: 1 }}>
            <Typography>Information bancaire</Typography>
            <TextField
              label="IBAN"
              size="small"
              fullWidth
              value={form.iban}
              onChange={(e) => setForm({ ...form, iban: e.target.value })}
              disabled={processing}
              required
            />
          </FormGroup>
          <CardActions>
            <Button
              disabled={processing}
              processing={processing}
              onClick={() => proceed()}
            >
              Envoyer
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};
