import React, { FC } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { useField } from "../hooks/field";

export const ColorField: FC<{
  field: string;
  label: string;
  required?: boolean;
}> = ({ field, label, required = false }) => {
  const { value, valid, onChange } = useField<string>(field);
  return (
    <Stack direction="row" spacing={1}>
      <TextField
        size="small"
        label={label}
        fullWidth
        value={value}
        required={required}
        onChange={(event) => onChange(event.target.value)}
        error={!valid}
      />
      <Box
        sx={{
          bgcolor: value,
          width: "40px",
          borderColor: "grey.400",
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: 1,
        }}
      ></Box>
    </Stack>
  );
};
