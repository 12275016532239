import React from "react";
import { SEO } from "../seo";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { navigate } from "gatsby";
import { Router, Redirect } from "@reach/router";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleIcon from "@mui/icons-material/People";
import { OrderList } from "../organisms/order-list";
import { ShopForm } from "../organisms/shop-form";
import { ShopList } from "../organisms/shop-list";
import { Directory } from "../organisms/directory";
import { ShopView } from "../organisms/shop-view";
import { LoginView } from "../organisms/login-view";
import { AdminProvider, useAdmin } from "../hooks/admin";
import { Toolbar } from "../molecules/toolbar";
import { LogoIcon } from "../atoms/logo-icon";
import { avatar } from "../utils/avatar";
import { useLocation } from "@reach/router";

const AdminView = () => {
  const { pathname } = useLocation();
  const { token, isAdmin, user, modal, setModal } = useAdmin();
  return token ? (
    <Box
      sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
      onDragOver={(event) => event.preventDefault()}
    >
      <SEO title={"Admin | Exoshop"} noindex={true} />
      <Drawer
        sx={{
          width: "14rem",
          zIndex: 1,
          ".MuiDrawer-paper": {
            width: "14rem",
          },
          ".MuiListItemButton-root": {
            margin: "0.25rem 0.5rem",
            padding: "0.25rem 1rem",
            borderRadius: "0.5rem",
            "&.Mui-selected,&.Mui-selected:hover": {
              bgcolor: "rgba(0, 0, 0, 0.08)",
            },
          },
          ".MuiListItemIcon-root": {
            minWidth: "2.5rem",
          },
        }}
        variant="permanent"
      >
        <Toolbar title="Exoshop" icon={<LogoIcon />} small />
        <List
          sx={{
            flex: 1,
            overflowY: "auto",
          }}
        >
          <ListItemButton
            selected={/^\/admin\/?$/.test(pathname)}
            onClick={() => navigate("/admin")}
          >
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Commandes" />
          </ListItemButton>
          {isAdmin && (
            <ListItemButton
              selected={/^\/admin\/shops/.test(pathname)}
              onClick={() => navigate("/admin/shops")}
            >
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText primary="Boutiques" />
            </ListItemButton>
          )}
          {isAdmin && (
            <ListItemButton
              selected={/^\/admin\/stripe/.test(pathname)}
              onClick={() => navigate("/admin/stripe")}
            >
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Stripe" />
            </ListItemButton>
          )}
          {isAdmin && (
            <ListItemButton
              selected={/^\/admin\/users/.test(pathname)}
              onClick={() => navigate("/admin/users")}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Utilisateurs" />
            </ListItemButton>
          )}
        </List>
        <Divider />
        <List sx={{ py: 0 }}>
          <ListItem sx={{ py: 1 }}>
            <ListItemAvatar sx={{ minWidth: "3rem" }}>
              <Avatar sx={{ width: 32, height: 32, fontSize: "1rem" }}>
                {avatar(user?.user_metadata?.full_name)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{
                ".MuiListItemText-primary": {
                  lineHeight: "1rem",
                },
                ".MuiListItemText-secondary": {
                  color: "grey",
                  lineHeight: "1rem",
                },
                my: 0,
              }}
              primary={user?.user_metadata?.full_name}
              secondary={
                user?.app_metadata?.roles?.[0] === "seller"
                  ? user?.app_metadata?.shop
                  : user?.app_metadata?.roles?.[0]
              }
            />
          </ListItem>
        </List>
      </Drawer>
      <Box sx={{ pl: "14rem" }}>
        <Router basepath="/admin">
          {isAdmin && <ShopForm path="/stripe" />}
          {isAdmin && <Directory path="/users" />}
          <ShopView path="/shops/:shopId/*" />
          <ShopList path="/shops" />
          <OrderList path="/" />
          <Redirect from="/" to="/admin" default noThrow />
        </Router>
      </Box>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            width: "320px",
            bgcolor: "background.default",
          },
        }}
        ModalProps={{ onBackdropClick: () => setModal(undefined) }}
        variant="temporary"
        anchor="right"
        open={!!modal}
      >
        {modal}
      </Drawer>
    </Box>
  ) : (
    <LoginView />
  );
};

export default () => {
  return (
    <AdminProvider>
      <AdminView />
    </AdminProvider>
  );
};
