import React, { FC, PropsWithChildren } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import { FieldContext, useField } from "../hooks/field";

export const GroupField: FC<
  PropsWithChildren<{
    field?: string;
    label: string;
    sortable?: boolean;
    dragged?: boolean;
    included?: boolean;
    onRemove?: () => void;
  }>
> = ({ field, label, sortable, dragged, included, onRemove, children }) => {
  const { value, validation, valid, onChange } = useField(field);
  return (
    <FieldContext.Provider value={{ value, validation, onChange }}>
      {included ? (
        children
      ) : (
        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true }}
          disableGutters
          sx={{
            borderRadius: 1,
            borderColor: dragged
              ? "background.paper"
              : valid
              ? "grey.400"
              : "error.main",
            bgcolor: dragged ? "background.paper" : "white",
            width: "100%",
            ":before": { display: "none" },
            transition: "none",
          }}
        >
          <AccordionSummary
            sx={{
              minHeight: "38px",
              height: "38px",
              width: "100%",
              ".MuiAccordionSummary-content": {
                display: "flex",
                overflow: "hidden",
                alignItems: "center",
                width: "100%",
              },
              opacity: dragged ? 0 : 1,
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            {sortable && (
              <DragHandleOutlinedIcon
                fontSize="small"
                sx={{
                  mr: 2,
                  ml: 0,
                  color: "text.secondary",
                }}
              />
            )}
            <Typography
              sx={{
                flex: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {label}
            </Typography>
            {onRemove && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemove();
                }}
              >
                <DeleteOutlineOutlined fontSize="small" />
              </IconButton>
            )}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              opacity: dragged ? 0 : 1,
            }}
          >
            <Stack spacing={2}>{children}</Stack>
          </AccordionDetails>
        </Accordion>
      )}
    </FieldContext.Provider>
  );
};
