import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import { Shop, validate, validateShop } from "../../api/shared/shop";
import { GatsbyImage } from "gatsby-plugin-image";
import { Toolbar } from "../molecules/toolbar";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useAdmin } from "../hooks/admin";
import { FieldContext } from "../hooks/field";
import { TextField } from "../atoms/text-field";
import { GatsbyShop, shop2GatsbyShop } from "../utils/shop";
import { useApi } from "../hooks/api";

const ToolBox: FC<{ onCreate: (shop: Shop) => void }> = ({ onCreate }) => {
  const [shop, setShop] = useState<Shop>({
    id: "",
    name: "",
    baseline: "",
    url: "",
    phone: "",
    color: "#C52F30",
    discounts: [],
    email: "",
    customDomain: false,
    domain: "",
    legal: {
      capital: "",
      company: "",
      contact: "",
      intracommunityVAT: "",
      phoneNumber: "",
      registeredOffice: "",
      responsible: "",
      siret: "",
    },
    products: [],
    reassurance: {
      shipping: {
        title: "Livraison",
        description: "A partir de 6 bouteilles",
        information: "",
      },
      delay: {
        title: "Expédition",
        description: "En 4 à 5 jours ouvrés",
        information: "",
      },
      payment: {
        title: "Paiement",
        description: "100% sécurisé via 3D Secure",
        information: "",
      },
    },
    shipping: {
      home: { enabled: false, method: "", surcharges: [], zones: [] },
      clickAndCollect: {
        address: "",
        city: "",
        enabled: false,
        opening: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
          holidays: false,
          hours: "",
        },
        zipcode: "",
      },
      fairs: [],
      minimum: 6,
      maximum: 1000,
      packing: 6,
    },
    exocep: { account: "", env: "" },
    stripe: { account: "", env: "" },
    fees: 0,
    enabled: false,
    show: false,
  });
  return (
    <FieldContext.Provider
      value={{
        value: shop,
        validation: validateShop(shop),
        onChange: (value) => setShop(value),
      }}
    >
      <Stack sx={{ p: 2 }} spacing={1}>
        <Typography variant="h6">Nouvelle boutique</Typography>
        <Divider />
        <TextField field="id" label="Identifiant" required />
        <TextField field="name" label="Nom" required />
        <Button
          variant="contained"
          onClick={() => onCreate(shop)}
          disableElevation
          disabled={!validate(validateShop(shop))}
        >
          Créer
        </Button>
      </Stack>
    </FieldContext.Provider>
  );
};

export const ShopList: FC<RouteComponentProps> = () => {
  const { setModal } = useAdmin();
  const { get, put } = useApi();
  const [_, setLoading] = useState(false);
  const [shops, setShops] = useState<Shop[]>([]);
  const loadData = async () => {
    setLoading(true);
    const {
      data: { data },
    } = await get<{ data: Shop[] }>("/api/shops");
    setShops(data);
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  const onAdd = () => {
    setModal(
      <ToolBox
        onCreate={async (shop) => {
          setModal(undefined);
          try {
            await put(`/api/shops/${shop.id}`, shop);
          } catch {
            // TODO display error
          } finally {
            await loadData();
          }
        }}
      />
    );
  };
  return (
    <Stack>
      <Toolbar
        title="Boutiques"
        icon={<StorefrontIcon />}
        actions={
          <Button
            onClick={onAdd}
            size="small"
            variant="contained"
            sx={{
              height: "2rem",
              bgcolor: "primary.contrastText",
              color: "primary.main",
              "&:hover": {
                bgcolor: "primary.contrastText",
              },
              "&.Mui-disabled": {
                bgcolor: "primary.contrastText",
                color: "primary.main",
                opacity: 0.5,
              },
            }}
            disableElevation
          >
            Ajouter
          </Button>
        }
      />
      <Box sx={{ p: 1 }}>
        <Grid container spacing={1}>
          {shops
            .map<GatsbyShop>((shop) => shop2GatsbyShop(shop)!)
            .map(({ id, name, logo, banner }) => (
              <Grid key={id} item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Link
                  href={`/admin/shops/${id}`}
                  sx={{
                    textDecoration: "none!important",
                    display: "block",
                    height: "100%",
                  }}
                >
                  <Card
                    elevation={0}
                    sx={{
                      position: "relative",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      ".banner": {
                        transition: "transform 1s",
                      },
                      ":hover": {
                        boxShadow:
                          "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.1)",
                        ".banner": {
                          transform: "scale(1.05)",
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: "12rem",
                        overflow: "hidden",
                        ".gatsby-image-wrapper": {
                          display: "block",
                          height: "12rem",
                        },
                        img: {
                          borderTopLeftRadius: "0.25rem",
                          borderTopRightRadius: "0.25rem",
                        },
                        bgcolor: "grey.300",
                      }}
                    >
                      {banner?.childImageSharp && (
                        <GatsbyImage
                          className="banner"
                          image={banner.childImageSharp.gatsbyImageData}
                          alt={name}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        left: "1rem",
                        top: "10.125rem",
                        p: "0.5rem",
                        backgroundColor: "common.white",
                        display: "inline-block",
                        borderRadius: "0.25rem",
                        boxShadow: "0px 1px 8px rgb(0 0 0 / 20%)",
                        width: 60,
                        height: 60,
                      }}
                    >
                      {logo?.childImageSharp && (
                        <GatsbyImage
                          image={logo.childImageSharp?.gatsbyImageData}
                          alt="logo"
                        />
                      )}
                    </Box>
                    <Box sx={{ p: 1.5 }}>
                      <Typography variant="h3" sx={{ pt: 3 }}>
                        {name}
                      </Typography>
                    </Box>
                  </Card>
                </Link>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Stack>
  );
};
