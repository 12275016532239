import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "gotrue-js";
import axios from "axios";
import { Shop } from "../../api/shared/shop";

type Env = "test" | "live";

const AdminContext = createContext<{
  token?: string;
  env?: Env;
  setToken: (token: string) => void;
  user?: User;
  setUser: (user?: User) => void;
  isAdmin: boolean;
  isSeller: boolean;
  modal?: ReactElement | undefined;
  setModal: (modal: ReactElement | undefined) => void;
}>({
  isAdmin: false,
  isSeller: false,
  setToken: () => void 0,
  setUser: () => void 0,
  setModal: () => void 0,
});

export const AdminProvider = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState<string>();
  const [env, setEnv] = useState<Env>();
  const [modal, setModal] = useState<ReactElement>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isSeller, setIsSeller] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      if (user) {
        const token = await user.jwt();
        const roles = user?.app_metadata?.roles || [];
        if (roles.includes("seller") && user?.app_metadata?.shop?.length) {
          const {
            data: {
              data: {
                stripe: { env },
              },
            },
          } = await axios.get<{ data: Shop }>(
            `/api/shops/${user?.app_metadata?.shop}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setEnv(env as Env);
        }
        setIsAdmin(roles.includes("admin"));
        setIsSeller(roles.includes("seller"));
        setToken(token);
      } else {
        setToken(undefined);
        setEnv(undefined);
        setIsAdmin(false);
        setIsSeller(false);
      }
    })();
  }, [user]);
  return (
    <AdminContext.Provider
      value={{
        token,
        setToken,
        user,
        env,
        setUser,
        isAdmin,
        isSeller,
        modal,
        setModal,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
