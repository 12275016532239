import { useAdmin } from "./admin";
import { useMemo } from "react";
import axios from "axios";

export const useApi = () => {
  const { token, setUser } = useAdmin();
  const instance = useMemo(() => {
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.isAxiosError && error.response.status === 401) {
          setUser(undefined);
          return { data: {} };
        } else throw error;
      }
    );
    return instance;
  }, [token]);
  return instance;
};
